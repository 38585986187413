<!--
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2022-03-12 17:06:14
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-03-15 11:41:08
-->
<template>
     <div class="hot-nav">
        <span>您所在的位置： </span>
        <span @click="goIndex">网站首页 ></span>
        <span>{{navTitle}}</span>
    </div>
</template>

<script>

export default {
    name:'HotNav',
    props:{
        navTitle:{
            required:true,
        }, 
    },
    methods: {
        goIndex(){
            this.$router.push('/index')
        }
    },
}
</script>

<style lang='less' scoped>
.hot-nav{
    width: 1200px;
    margin: 30px auto 22px ;
    font-size: 16px;
    color: #666666;
    >span:nth-child(2){
        cursor: pointer;
    }
    >span:nth-child(3){
        color: red;
        // cursor: pointer;
    }
}
</style>
